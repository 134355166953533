<template>
  <div>
    <div class="filling">
      <p style="text-align: center;font-size:180px;font-weight: 100;color:#999999" @click="dialogVisible = true"> +</p>
      <p style="text-align: center;color:#999999"> 添加学员信息</p>
    </div>
    <el-dialog :visible.sync="dialogVisible" class="cre" title='添加学员信息' width="1080px" append-to-body
      :before-close="handleClose">
      <create_user @test='close'>
      </create_user>
    </el-dialog>
  </div>
</template>

<script>
import create_user from "./components/CreateUser.vue";
export default {
  name: "",
  components: {
    // reportList,
    create_user,
  },
  data() {
    return {
      activeName: ["1", "2", "3", "4"],
      dialogVisible: false,
    };
  },

  methods: {
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => { });
    },
    close() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang='less' scoped>
.filling {
  margin-top: 10px;
  padding: 20px;
  background: white;
  margin-bottom: 20px;
}
</style>

<style lang="less">
.cre {
  .el-icon-arrow-right:before {
    content: none;
  }

  .el-dialog__header {
    background: #5b97f9;
    text-align: center;
    padding-top: 14px;
    padding: 20px 20px 20px;

    span {
      color: white;
      position: relative;
      top: 3px;
      font-size: 16px;
    }
  }

  .el-dialog__headerbtn .el-dialog__close {
    color: #fff;
  }

  .el-dialog__body {
    padding: 30px 20px 10px 40px;
  }

  .content {
    margin-bottom: 10px;
  }

  .title_collapse {
    width: 300px;
    margin: 0 400px;
    letter-spacing: 1px;
    background: #5b97f9;
    text-align: center;
    color: white;
    border-radius: 0 0 100px 100px;
  }

  .user_brief {
    span:first-child {
      color: red;
    }

    span:last-child {
      list-style: disc;
      border: 1px solid #979292;
      padding: 3px;
      color: green;
    }
  }
}
</style>